// utils
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function createIntent(
  data,
  responseType,
  interactiveType,
  interactiveButtonsHeaderType,
  interactiveButtonsCounter,
  flowId,
  flowHeaderType,
  optionsArray
) {
  let formData = new FormData();

  formData.append("intent_name", data.intentName);

  formData.append("training_phrases", data.trainingPhrases);

  formData.append("response_type", responseType);

  if (responseType === "text") {
    formData.append("response_text", data.responseText);
  }

  if (responseType === "image") {
    formData.append("file", data.file);
  }

  if (responseType === "pdf") {
    formData.append("file", data.file);
  }

  if (responseType === "interactive" && interactiveType === "list") {
    formData.append("interactive_type", "list");
    formData.append(
      "interactive_header",
      JSON.stringify({
        type: "text",
        text: data.header,
      })
    );
    formData.append("interactive_body", data.body);
    formData.append("interactive_action_button_text", data.buttonText);

    const optionTextValues = optionsArray
      ?.map((option) => option.optionText)
      .join(",");

    formData.append("interactive_action_button_options", optionTextValues);
  }

  if (responseType === "interactive" && interactiveType === "buttons") {
    formData.append("interactive_type", "button");
    interactiveButtonsHeaderType === "text" &&
      formData.append(
        "interactive_header",
        JSON.stringify({
          type: "text",
          text: data.buttonHeader,
        })
      );
    interactiveButtonsHeaderType === "image" &&
      formData.append("interactive_header", data.file);
    interactiveButtonsHeaderType === "video" &&
      formData.append("interactive_header", data.file);
    interactiveButtonsHeaderType === "document" &&
      formData.append("interactive_header", data.file);

    formData.append("interactive_body", data.buttonBody);

    interactiveButtonsCounter === "1" &&
      formData.append(
        "interactive_action_button_options",
        `${data.buttonText1}`
      );

    interactiveButtonsCounter === "2" &&
      formData.append(
        "interactive_action_button_options",
        `${data.buttonText1},${data.buttonText2}`
      );

    interactiveButtonsCounter === "3" &&
      formData.append(
        "interactive_action_button_options",
        `${data.buttonText1},${data.buttonText2},${data.buttonText3}`
      );
  }

  if (responseType === "interactive" && interactiveType === "flow") {
    formData.append("interactive_type", "flow");

    flowHeaderType === "text" &&
      formData.append(
        "interactive_header",
        JSON.stringify({
          type: "text",
          text: data.flowHeader,
        })
      );
    flowHeaderType === "image" &&
      formData.append("interactive_header", data.file);
    flowHeaderType === "video" &&
      formData.append("interactive_header", data.file);
    flowHeaderType === "document" &&
      formData.append("interactive_header", data.file);

    formData.append("interactive_body", data.flowBody);

    formData.append("interactive_action_button_text", data.flowButtonText);

    formData.append("flow_id", flowId);
  }

  try {
    // console.log([...formData.entries()]);
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `lms_chat/api/google/dialogflow/intents`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
      });
      const res = await response.data;
      return res;
    }
  } catch (error) {
    console.log(error);
    throw new Error(Object.values(error.errors));
  }
}
