// // utils
// import axios from "src/utils/axios";
// import { isValidToken } from "src/utils/jwt";

// export default async function getUnsubscribersList(page, limit, search) {
//   try {
//     const accessToken = localStorage.getItem("accessToken");
//     if (isValidToken(accessToken)) {
//       const response = await axios({
//         method: "get",
//         url: `lms_campaign/api/whatsapp/unsubscribe`,
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       const res = await response.data;
//       return res;
//     }
//   } catch (error) {
//     throw new Error(Object.values(error.errors));
//   }
// }

// utils
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function getUnsubscribersList(
  page = 1,
  limit = 25,
  search = ""
) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      let apiUrl = `lms_campaign/api/whatsapp/unsubscribe?page=${page}&limit=${limit}`;
      if (search) {
        apiUrl += `&search=${encodeURIComponent(search)}`;
      }

      const response = await axios({
        method: "get",
        url: apiUrl,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data;
    }
  } catch (error) {
    throw new Error(Object.values(error.errors));
  }
}
