// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// Api's
import manageApiKeys from "src/services/GMB/GoogleMapsApiKeys/apiKeys";

// Initial State
const initialState = {
  api_keys: [],
  loader: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { api_keys } = action.payload;
    return {
      ...state,
      api_keys,
      loader: false,
    };
  },
  UPDATE: (state, action) => {
    const { api_keys } = action.payload;
    return {
      ...state,
      api_keys,
      loader: false,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const GoogleMapsApiKeysContext = createContext();

// Provider Component
export const GoogleMapsApiKeysProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const { api_keys } = await manageApiKeys("get");
      dispatch({
        type: "INITIALIZE",
        payload: {
          api_keys,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const update = async (api_keys) => {
    try {
      dispatch({
        type: "UPDATE",
        payload: {
          api_keys,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <GoogleMapsApiKeysContext.Provider
      value={{
        api_keys: state.api_keys,
        loader: state.loader,
        update,
      }}
    >
      <Outlet />
    </GoogleMapsApiKeysContext.Provider>
  );
};
