import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// @mui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
//
import RejectionFiles from "./RejectionFiles";
import BlockContent from "./BlockContent";
import Image from "../Image";
// assets
import excelIcon from "../../assets/excel.svg";
import pdfIcon from "src/assets/images/pdf.png";
import videoIcon from "src/assets/images/film.png";

// utils
import { getFileThumb } from "src/utils/getFileFormat";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  padding: "10px",
  borderRadius: theme.shape.borderRadius,
  // transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  sx,
  label,
  thumbnailSize,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    ...other,
  });

  const fileType =
    file?.name?.substring(file?.name.lastIndexOf(".") + 1, file?.name.length) ||
    file;

  // console.log(file);

  let icon = { value: false };

  if (fileType === "xlsx") {
    icon.value = excelIcon;
  }

  if (fileType === "pdf") {
    icon.value = pdfIcon;
  }

  if (fileType === "mp4") {
    icon.value = videoIcon;
  }

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent
          label={label ? label : false}
          preview={file ? getFileThumb(file.name, thumbnailSize || 100) : false}
          fileName={file && file.name}
          {...other}
        />

        {/* {file && (
          <Image
            alt="file preview"
            src={typeof file === "string" ? file : excelIcon}
            sx={{
              top: 8,
              left: 8,
              borderRadius: 1,
              position: "absolute",
              width: "100px",
              height: "100px",
            }}
          />
        )} */}
      </DropZoneStyle>

      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}

      {helperText && helperText}
    </Box>
  );
}
