// scroll bar
import "simplebar/src/simplebar.css";
// utils
import axios from "./utils/axios";
// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import React, { useState, useEffect, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// contexts
import { SettingsProvider } from "./contexts/SettingsContext";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { AuthProvider } from "./contexts/JWTContext";
//
import App from "./App";
import LoadingScreen from "./components/LoadingScreen";

// ----------------------------------------------------------------------

const Index = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to fetch whitelabel config data
    const fetchWhitelabelConfigData = async () => {
      try {
        const url = new URL(window.location.href);
        const hostUrl = url.origin;
        const response = await axios.get(
          "/lms_user/api/whitelable_config_display",
          { params: { host_url: hostUrl } }
        );

        const {
          landing_logo,
          favicon_logo,
          display_name,
          meta_title,
          meta_discription,
          meta_keywords,
        } = response.data || {};
        localStorage.setItem("landingLogo", landing_logo);
        localStorage.setItem("faviconLogo", favicon_logo);
        localStorage.setItem("displayName", display_name);
        localStorage.setItem("metaTitle", meta_title);
        localStorage.setItem("metaDiscription", meta_discription);
        localStorage.setItem("metaKeywords", meta_keywords);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchWhitelabelConfigData();
  }, []);

  // Loader to show till the whitelabel data is fetched
  if (loading) {
    return <Suspense fallback={<LoadingScreen isDashboard />} />;
  }

  return (
    <AuthProvider>
      <HelmetProvider>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </HelmetProvider>
    </AuthProvider>
  );
};

// ----------------------------------------------------------------------

ReactDOM.render(<Index />, document.getElementById("root"));
