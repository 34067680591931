import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function EnableDisableCatalogueVisibility(status) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `lms_campaign/api/whatsapp/catalog/visibility/${status}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const res = await response.data;
      return res;
    }
  } catch (error) {
    throw error;
  }
}
