import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function getCampaigns(
  gId,
  clientId,
  dateRange,
  campaignId,
  metricsArray
) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "get",
        url: `https://demoads.emovur.xyz/api/v1/ads/campaign/get_campaigns`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          googleUserId: gId,
          clientId: clientId,
          dates: dateRange,
          campaignId: campaignId,
          metricValues: metricsArray,
        },
      });
      const res = response.data;
      return res;
    }
  } catch (error) {
    throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
  }
}
