import { v4 as uuidv4 } from "uuid";
import React, { createContext, useCallback, useMemo, useReducer } from "react";
import { Outlet } from "react-router-dom";

export const CreateFlowContext = createContext();

const initialState = {
  createflowsname: { categories: [], name: "" },
  cardsData: [
    {
      id: uuidv4(),
      screenName: "Form",
      screenData: [
        {
          id: uuidv4(),
          type: "short_answer",
          inputType: "text",
          label: "",
          multipleOption: [],
          imageUrl: "", // For storing image URL or any image-related data
          required: true,
          helperText: "",
        },
      ],
      buttonText: "",
    },
  ],
  currentScreen: null,
  selectedOptionType: "Short_answer",
  anchorEl: null,
  textIconAnchorEl: null,
  inputContinue: "Continue",
};

const cardReducer = (state, action) => {
  switch (action.type) {
    // FORM 1 HANDLERS
    case "UPDATE_CATEGORY": {
      const { categories } = action.payload;
      return {
        ...state,
        createflowsname: { ...state.createflowsname, categories },
      };
    }
    case "UPDATE_NAME": {
      const { name } = action.payload;
      return {
        ...state,
        createflowsname: { ...state.createflowsname, name },
      };
    }
    case "UPDATE_LANGUAGES": {
      const { languages } = action.payload;
      return {
        ...state,
        createflowsname: { ...state.createflowsname, languages },
      };
    }

    case "SET_cardsData":
      return { ...state, cardsData: action.payload };
    case "SET_CURRENT_SCREEN":
      return { ...state, currentScreen: action.payload };
    case "SET_SELECTED_OPTION_TYPE":
      return { ...state, selectedOptionType: action.payload };
    case "SET_ANCHOR_EL":
      return { ...state, anchorEl: action.payload };
    case "SET_TEXT_ICON_ANCHOR_EL":
      return { ...state, textIconAnchorEl: action.payload };
    case "SET_INPUT_CONTINUE":
      return { ...state, inputContinue: action.payload };
    case "SET_REQUIRED":
      return {
        ...state,
        cardsData: state.cardsData.map((card) =>
          card.id === state.currentScreen
            ? {
                ...card,
                screenData: card.screenData.map((qCard) =>
                  qCard.id === action.payload.questionId
                    ? {
                        ...qCard,
                        required: action.payload.optionType,
                      }
                    : qCard
                ),
              }
            : card
        ),
      };
    case "ADD_SCREEN":
      return {
        ...state,
        cardsData: [...state.cardsData, action.payload],
      };
    case "DELETE_SCREEN":
      return {
        ...state,
        cardsData: state.cardsData.filter((card) => card.id !== action.payload),
      };
    case "ADD_QUESTION_CARD":
      return {
        ...state,
        cardsData: state.cardsData.map((card) =>
          card.id === state.currentScreen
            ? {
                ...card,
                screenData: [
                  ...card.screenData,
                  {
                    id: uuidv4(),
                    type: "short_answer",
                    inputType: "text",
                    label: "",
                    multipleOption: [],
                    required: true,
                    helperText: "",
                  },
                ],
              }
            : card
        ),
      };
    case "ADD_OPTION":
      return {
        ...state,
        cardsData: state.cardsData.map((card) =>
          card.id === state.currentScreen
            ? {
                ...card,
                screenData: card.screenData.map((qCard) =>
                  qCard.id === action.payload.questionId
                    ? {
                        ...qCard,
                        type: action.payload.optionType,
                        multipleOption:
                          action.payload.optionType === "Image"
                            ? qCard.multipleOption
                            : [
                                ...qCard.multipleOption,
                                {
                                  id: uuidv4(),
                                  label: "",
                                },
                              ],
                      }
                    : qCard
                ),
              }
            : card
        ),
      };

    case "REMOVE_QUESTION_CARD":
      return {
        ...state,
        cardsData: state.cardsData.map((card) =>
          card.id === state.currentScreen
            ? {
                ...card,
                screenData: card.screenData.filter(
                  (qCard) => qCard.id !== action.payload
                ),
              }
            : card
        ),
      };
    case "UPDATE_CARD_TEXT":
      return {
        ...state,
        cardsData: state.cardsData.map((card) =>
          card.id === state.currentScreen
            ? { ...card, text: action.payload }
            : card
        ),
      };
    case "UPDATE_QUESTION_CARD_LABEL":
      return {
        ...state,
        cardsData: state.cardsData.map((card) =>
          card.id === state.currentScreen
            ? {
                ...card,
                data: card.data.map((qCard) =>
                  qCard.id === action.payload.qCardId
                    ? { ...qCard, label: action.payload.label }
                    : qCard
                ),
              }
            : card
        ),
      };
    default:
      return state;
  }
};

export const CreateFlowProvider = () => {
  const [state, dispatch] = useReducer(cardReducer, initialState);

  const updateCategory = (categories) => {
    dispatch({ type: "UPDATE_CATEGORY", payload: { categories } });
  };
  const updateName = (name) => {
    dispatch({ type: "UPDATE_NAME", payload: { name } });
  };
  const updateLanguages = (languages) => {
    dispatch({ type: "UPDATE_LANGUAGES", payload: { languages } });
  };
  // Component functions

  const setCardsData = useCallback((updatedCards) => {
    dispatch({ type: "SET_cardsData", payload: updatedCards });
  }, []);

  const setRequired = (questionId, optionType) => {
    dispatch({ type: "SET_REQUIRED", payload: { questionId, optionType } });
  };

  const setSelectedOptionType = (type) => {
    dispatch({ type: "SET_SELECTED_OPTION_TYPE", payload: type });
  };

  const addScreen = (newScreen) => {
    dispatch({ type: "ADD_SCREEN", payload: newScreen });
  };

  const deleteScreen = (id) => {
    dispatch({ type: "DELETE_SCREEN", payload: id });
  };

  const setCurrentScreen = useCallback((screenId) => {
    dispatch({ type: "SET_CURRENT_SCREEN", payload: screenId });
  }, []);

  const _addQuestionCard = () => {
    dispatch({ type: "ADD_QUESTION_CARD" });
  };

  const addOptionCards = (questionId, optionType) => {
    dispatch({ type: "ADD_OPTION", payload: { questionId, optionType } });
  };

  const _removeQuestionCard = (questionId) => {
    dispatch({ type: "REMOVE_QUESTION_CARD", payload: questionId });
  };

  const updateCardText = (text) => {
    dispatch({ type: "UPDATE_CARD_TEXT", payload: text });
  };

  const updateQuestionCardLabel = (qCardId, label) => {
    dispatch({
      type: "UPDATE_QUESTION_CARD_LABEL",
      payload: { qCardId, label },
    });
  };

  const setAnchorEl = (element) => {
    dispatch({ type: "SET_ANCHOR_EL", payload: element });
  };

  const setTextIconAnchorEl = (element) => {
    dispatch({ type: "SET_TEXT_ICON_ANCHOR_EL", payload: element });
  };

  const setInputContinue = (text) => {
    dispatch({ type: "SET_INPUT_CONTINUE", payload: text });
  };

  // Expose state and dispatch function to the context
  const contextValue = useMemo(
    () => ({
      cardsData: state.cardsData,
      currentScreen: state.currentScreen,
      selectedOptionType: state.selectedOptionType,
      state,
      createflowsname: state.createflowsname,
      updateCategory,
      updateName,
      updateLanguages,
      setCardsData,
      setRequired,
      setSelectedOptionType,
      addScreen,
      deleteScreen,
      setCurrentScreen,
      _addQuestionCard,
      addOptionCards,
      _removeQuestionCard,
      updateCardText,
      updateQuestionCardLabel,
      setAnchorEl,
      setTextIconAnchorEl,
      setInputContinue,
    }),
    [setCardsData, setCurrentScreen, state]
  );
  return (
    <CreateFlowContext.Provider value={contextValue}>
      <Outlet />
    </CreateFlowContext.Provider>
  );
};
