import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef, useEffect, useState } from "react";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = "", meta, ...other }, ref) => {
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    const currentName = localStorage.getItem("displayName");
    if (currentName && currentName !== "null") {
      setDisplayName(currentName);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${title}${displayName ? ` | ${displayName}` : ""}`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
