// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
import getAppSettings from "src/services/chatApp/getAppSettings";

// Initial State
const initialState = {
  details: {},
  loading: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { details } = action.payload;
    return { ...state, details, loading: false };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const NewChatAppSettingsContext = createContext();

// Provider Component
export const NewChatAppSettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const { details } = await getAppSettings();
      dispatch({ type: "INITIALIZE", payload: { details } });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <NewChatAppSettingsContext.Provider
      value={{
        newChatAppSettings: state.details,
        loading: state.loading,
        initialize,
      }}
    >
      <Outlet />
    </NewChatAppSettingsContext.Provider>
  );
};
