import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function getAccessibleCustomers(gId) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "get",
        url: `https://demoads.emovur.xyz/api/v1/ads/customer/get_accessible_customers`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { googleUserId: gId },
      });
      const res = response.data;
      return res;
    }
  } catch (error) {
    throw error;
  }
}
